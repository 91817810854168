<template>
    <div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item text-secondary">
										<router-link to="/member/profile" class=" text-decoration-none">Member</router-link>
									</li>
									<li class="breadcrumb-item active" aria-current="page">Produk Favorit</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<div class="col-md-12 mb-4">
					<div class="card shadow border-primary round">
						<div class="card-body p-4">
							<h4 class="text-center my-2"><i class="fa fa-heart mr-3"></i>Produk Favorit</h4>
							<hr />
							<div class="text-center my-3" v-if="isRefresh">
								<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
							</div>
							<div class="px-3 py-2" v-else>
								<div v-if="total_product > 0">
									<div class="row max-content-user customScroolBar">
										<div class="col-md-6 mb-2 p-1" v-for="product in produk_favorite" :key="product.wis_prod_id">
											<div class="row border round d-flex align-items-center p-3 shadow-sm mx-1">
												<div class="col-lg-4 px-2">
													<router-link :to="'/product-detail/'+product.wis_prod_id+'-'+product.prod_name.toLowerCase()" class="text-decoration-none">
														<img v-lazy="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top" :alt="product.prod_image" />
													</router-link>
												</div>
												<div class="col-lg-8 text-left px-2">
													<router-link :to="'/product-detail/'+product.wis_prod_id+'-'+product.prod_name.toLowerCase()" class="text-decoration-none">
														<h5 class="card-text text-secondary mb-1">{{ product.prod_name | capitalize }}</h5>
														<p class="text-muted mb-1">{{ product.prod_summary | str_limit(50) }}</p>
													</router-link>
													
													<!-- <div class="d-flex justify-content-start align-content-center mb-1" v-if="product.prod_discount && product.prod_discount != 0">
														<h5 class="text-success mb-1">{{ discountedPrice(product.prod_price, product.prod_discount) | formatCurrency('IDR') }}</h5>
														<p class="bg-danger text-white round px-2 ml-3 mb-1">{{ product.prod_discount }}%</p> 
														<strike class="text-muted pl-1">{{ product.prod_price | formatCurrency('IDR') }}</strike>
													</div>
													<h5 class="text-success mb-1" v-else>{{ product.prod_price | formatCurrency('IDR') }}</h5> -->
													<h5 class="text-success mb-1">-</h5>
													<div class="w-100 d-inline-flex mt-2">
														<!-- <button class="btn btn-outline-success w-50 m-1" @click="plusKeranjang(product.wis_prod_id)"><i class="fa fa-cart-plus"></i> Keranjang</button> -->
														<button class="btn btn-outline-success w-50 m-1" disabled><i class="fa fa-cart-plus"></i> Keranjang</button>
														<button class="btn btn-outline-info w-25 m-1" @click="add_compare(product.wis_prod_id)"><i class="fa fa-sliders-h"></i></button>
														<button class="btn btn-outline-danger w-25 m-1" @click="delete_productFavorite(product.wis_id)"><i class="fa fa-trash"></i></button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-12 w-100 text-center mt-1">
											<hr />
											<b-pagination v-model="current_page" :per-page="per_page" @change="get_productsFavorite" :total-rows="total_product" first-number last-number align="center" size="md"></b-pagination>
										</div>
									</div>
								</div>
								<div v-else>
									<div class="text-center my-3">
										<h4 class="text-info mb-1">Oops!</h4>
										<p class="text-muted">Belum ada produk yang difavoritkan</p>
										<router-link to="/product-list?type=product&q=Semua Produk" class="btn btn-outline-primary rounded pb-1"><h5>Ayo Favoritkan Sekarang!</h5></router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </main>
    </div>
</template>

<script>

export default {
	data () {
 		return {
			produk_favorite: [],
			current_page: 1,
			per_page: 0,
			total_product: 0,
			isRefresh: false,
 		}
	},
	methods: {
		get_productsFavorite(current_page) {
			this.isRefresh = true
			window.axios.get('/wishlist?page='+ current_page, {
       		        headers: {
						"token": this.$store.getters.token_member
       		        }
				}
			)
 			.then((response) => {
				this.per_page = response.data.data.per_page
				this.total_product = response.data.data.total
				this.current_page = current_page
				this.produk_favorite = response.data.data.data

				let position = $(document).scrollTop()
				if (position > 0) {
					$('html, body').stop().animate({
						scrollTop: 83
					}, 1000, 'easeInOutExpo')
				}
				this.isRefresh = false
 			})
 			.catch((error) => {
 				console.log(error)
			})
		},
		delete_productFavorite(id) {
			if(this.isLoggedIn) {
				window.axios.delete('/wishlist/'+ id, {
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
							"token": this.$store.getters.token_member
						}
					}
				)
				.then((response) => {
					this.get_productsFavorite()
					try {
						window.NavbarDirtyAccess.get_productFavorite()
					}
					catch(error) {}
					let position = $(document).scrollTop()
					if (position > 0) {
						$('html, body').stop().animate({
								scrollTop: 83
							}, 1000, 'easeInOutExpo')
						}
					})
				.catch((error) => {
					console.log(error)
				})
			}
		},
		plusKeranjang(id) {
			if(this.isLoggedIn) {
				let formData = new FormData();
				formData.append('prod_id', id);
				formData.append('prod_qty', 1);

				window.axios.post('/cart', formData, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
							"token": this.$store.getters.token_member
                        }
                    }
                )
 				.then((response) => {
					window.NavbarDirtyAccess.get_productKeranjang()
					this.$router.push('/member/shopping-cart')
 				})
 				.catch((error) => {
 					console.log(error)
				})
			}
			else {
				this.$router.push('/login')
			}
		},
		add_compare(id) {
			window.axios.get('/catalogue/detail/'+ id)
 			.then((response) => {
				
				let product = JSON.stringify(response.data.data)
				let id_productSaved = ''
				let flag = 1
				
				if(!localStorage.getItem('prod_compare_0')) {
					
					// CEK APAKAH PRODUK SUDAH ADA DI LOCALSTORAGE (MENGHINDARI DUPLIKASI DATA)
					if(localStorage.getItem('prod_compare_1')) {
						id_productSaved = JSON.parse(localStorage.getItem('prod_compare_1')).prod_id
						
						if(id_productSaved == id) {
							flag = 0
						}
					}
					if(localStorage.getItem('prod_compare_2')) {
						id_productSaved = JSON.parse(localStorage.getItem('prod_compare_2')).prod_id
						
						if(id_productSaved == id) {
							flag = 0
						}
					}
					
					if(flag == 1) {
						localStorage.setItem('prod_compare_0', product)
					}
				}
				else {
					if(!localStorage.getItem('prod_compare_1')) {
						// CEK APAKAH PRODUK SUDAH ADA DI LOCALSTORAGE (MENGHINDARI DUPLIKASI DATA)
						if(localStorage.getItem('prod_compare_0')) {
							id_productSaved = JSON.parse(localStorage.getItem('prod_compare_0')).prod_id
							
							if(id_productSaved == id) {
								flag = 0
							}
						}
						if(localStorage.getItem('prod_compare_2')) {
							id_productSaved = JSON.parse(localStorage.getItem('prod_compare_2')).prod_id
							
							if(id_productSaved == id) {
								flag = 0
							}
						}
						
						if(flag == 1) {
							localStorage.setItem('prod_compare_1', product)
						}
					}
					else {
						// CEK APAKAH PRODUK SUDAH ADA DI LOCALSTORAGE (MENGHINDARI DUPLIKASI DATA)
						if(localStorage.getItem('prod_compare_0')) {
							id_productSaved = JSON.parse(localStorage.getItem('prod_compare_0')).prod_id
							
							if(id_productSaved == id) {
								flag = 0
							}
						}
						if(localStorage.getItem('prod_compare_1')) {
							id_productSaved = JSON.parse(localStorage.getItem('prod_compare_1')).prod_id
							
							if(id_productSaved == id) {
								flag = 0
							}
						}
						
						if(flag == 1) {
							localStorage.setItem('prod_compare_2', product)
						}
					}
				}
				
				if(flag == 0) {
					this.$swal({
						title: 'Oops!', 
						html: '<p>Produk sudah pernah ditambahkan sebelumnya.</p>',
						icon: 'error',
						timer: 7000,
						showConfirmButton: false,
					});
				}
 			})
 			.catch((error) => {
				if(!error.response.data.success) {
					this.$swal({
						title: 'Oops!', 
						html: '<p>'+ error.response.data.message +'</p>',
						icon: 'error',
						timer: 7000,
						showConfirmButton: false,
					});
				}
			})
			.finally(() => {
				this.$router.push('/product/compare')
			})
		},
	},
	computed: {
      	isLoggedIn : function(){
      	  	return this.$store.getters.isLoggedIn
      	},
		isLoading() {
			return this.$store.getters.isLoading
		},
	},
    created () {
		$(document).scrollTop(0)
		this.get_productsFavorite(1)
		window.productFavoriteDirtyAccess = this;
	}
}
</script>